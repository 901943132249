const stagingConfig = {
  apiKey: "AIzaSyCjct8gKo9U3g4nORom093iDI6uivX64LU",
  authDomain: "poslog-staging.firebaseapp.com",
  databaseURL: "https://poslog-staging.firebaseio.com",
  projectId: "poslog-staging",
  storageBucket: "poslog-staging.appspot.com",
  messagingSenderId: "1029477762372",
  appId: "1:1029477762372:web:50984a49b71e9525bb4311",
  measurementId: "G-7C32LE4BMZ",
};

const prodConfig = {
  apiKey: "AIzaSyATFGJevnBHo1IpNXjJayW1vLZX-ydBVaI",
  authDomain: "poslog-prod.firebaseapp.com",
  databaseURL: "https://poslog-prod.firebaseio.com",
  projectId: "poslog-prod",
  storageBucket: "poslog-prod.appspot.com",
  messagingSenderId: "640559647039",
  appId: "1:640559647039:web:b7ef3e6329020944b9eb8a",
  measurementId: "G-ZXG8VW6L87",
};

const config =
  process.env.REACT_APP_ENV === "prod" ? prodConfig : stagingConfig;

export default config;

import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

export interface Event {
  target: {
    value: string;
    checked: boolean;
  };
}

interface InputFieldProps {
  label: string;
  min?: number;
  onChange: (event: Event) => void;
  pattern?: string;
  placeholder?: string;
  required: boolean;
  title?: string;
  type: "text" | "email" | "password";
  value: string;
}

export default function InputField({
  label,
  required,
  type,
  value,
  placeholder,
  min,
  pattern,
  title,
  onChange,
}: InputFieldProps) {
  const { t } = useTranslation();
  return (
    <label className={styles.inputField}>
      <div>
        <span className={styles.label}>{label}</span>
        {required && (
          <span className={`${styles.label} ${styles.required}`}>
            {t("FORM_REQUIRED")}
          </span>
        )}
      </div>
      <input
        className={styles.input}
        placeholder={placeholder}
        type={type}
        required={required}
        value={value}
        minLength={min}
        pattern={pattern}
        title={title}
        onChange={onChange}
        autoComplete="on"
      />
    </label>
  );
}

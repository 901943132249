import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAuth, sendEmailVerification } from "firebase/auth";

import { useUser } from "contexts/UserContext";
import { useFirebase } from "contexts/FirebaseContext";

import Button from "components/Button";
import Container from "components/Container";
import PageSectionLayout from "components/PageSectionLayout";
import Wrapper from "components/Wrapper";

import styles from "./index.module.scss";

export default function VerifyEmail() {
  const app = useFirebase();
  const auth = getAuth(app);
  const user = useUser();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const cautions: string[] = t("PAGE_CHECK_EMAIL_CAUTIONS", {
    returnObjects: true,
  });

  const resendEmail = async (): Promise<void> => {
    if (user == null) {
      return;
    }
    try {
      await sendEmailVerification(user);
      alert(t("PAGE_CHECK_EMAIL_ALERT_MAIL_RESEND"));
    } catch (error) {
      alert(error);
    }
  };

  const checkEmailVerified = async (): Promise<void> => {
    await auth.currentUser?.reload();
    if (auth.currentUser?.emailVerified === true) {
      navigate("/plans");
    } else {
      alert(t("PAGE_CHECK_EMAIL_ALERT_MAIL_NOT_VERIFICATION"));
    }
  };

  return (
    <Wrapper>
      <Container>
        <PageSectionLayout title={t("PAGE_CHECK_EMAIL_TITLE")}>
          <h2 className={styles.email}>{user?.email}</h2>
          <p className={styles.body}>{t("PAGE_CHECK_EMAIL_SUBTITLE")}</p>
          <div className={styles.actions}>
            <div className={styles.case}>
              <p className={styles.body}>
                {t("PAGE_CHECK_EMAIL_CASE_CONFIRMED")}
              </p>
              <div className={styles.button}>
                <Button
                  type="button"
                  label={t("PAGE_CHECK_EMAIL_CASE_CONFIRMED_BUTTON")}
                  variant="contained"
                  onClick={checkEmailVerified}
                ></Button>
              </div>
            </div>
            <div className={styles.case}>
              <p className={styles.body}>
                {t("PAGE_CHECK_EMAIL_CASE_MAIL_NOT_RECIVED")}
              </p>
              <div className={styles.button}>
                <Button
                  type="submit"
                  label={t("PAGE_CHECK_EMAIL_CASE_MAIL_NOT_RECIVED_BUTTON")}
                  variant="outline"
                  onClick={resendEmail}
                ></Button>
              </div>
              <ul className={styles.caution}>
                {cautions.map((caution) => (
                  <li key={caution}>{caution}</li>
                ))}
              </ul>
            </div>
          </div>
        </PageSectionLayout>
      </Container>
    </Wrapper>
  );
}

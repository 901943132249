import { useTranslation } from "react-i18next";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  DocumentData,
} from "firebase/firestore";

import plansConfig from "configs/plans";
import { useFirebase } from "contexts/FirebaseContext";
import { useUser } from "contexts/UserContext";

import Wrapper from "components/Wrapper";
import PageSectionLayout from "components/PageSectionLayout";

import styles from "./index.module.scss";
import PlanCard from "./components/PlanCard";

interface Plan {
  name: string;
  price: string;
  priceLabel: string;
  description: string;
}

interface PlansTranslation {
  PRO: { NAME: string; DESCRIPTION: string };
  TEAM: { NAME: string; DESCRIPTION: string };
  BUSINESS: { NAME: string; DESCRIPTION: string };
}

export default function Plans() {
  const { t, i18n } = useTranslation();
  const app = useFirebase();
  const user = useUser();
  const firestore = getFirestore(app);

  const language = (): "en" | "ja" => {
    switch (i18n.language) {
      case "ja":
        return "ja";
      default:
        return "en";
    }
  };

  const plansTranslation: PlansTranslation = t("PAGE_PLANS_PLANS", {
    returnObjects: true,
  });

  const plans: Plan[] = plansConfig.map((planConfig) => {
    const key = planConfig.translationKey as keyof PlansTranslation;
    return {
      name: plansTranslation[key].NAME,
      price: planConfig.price.id,
      priceLabel: planConfig.price[language()],
      description: plansTranslation[key].DESCRIPTION,
    };
  });

  const navigateToCheckout = async (plan: Plan): Promise<void> => {
    if (user == null) {
      return;
    }
    const customerRef = doc(firestore, "customers", user.uid);
    const checkoutSessionsRef = collection(customerRef, "checkout_sessions");
    const checkoutSessionRef = await addDoc(checkoutSessionsRef, {
      price: plan.price,
      automatic_tax: true,
      allow_promotion_codes: true,
      success_url: t("URL") + "/thanks",
      cancel_url: t("URL"),
    });
    onSnapshot(checkoutSessionRef, (checkoutSession: DocumentData) => {
      const { error, url } = checkoutSession.data();
      if (error !== undefined) {
        alert(t("PAGE_PLANS_ERROR"));
        return;
      }
      if (url !== undefined) {
        window.location.assign(url);
      }
    });
  };

  if (!user) {
    return;
  }

  return (
    <Wrapper style={{ background: "white" }}>
      <PageSectionLayout
        title={t("PAGE_PLANS_TITLE")}
        subtitle={t("PAGE_PLANS_SUBTITLE")}
      >
        <div className={styles.plans}>
          {plans.map((plan, index) => {
            return (
              <div className={styles.card} key={index}>
                <PlanCard
                  key={index}
                  name={plan.name}
                  price={plan.priceLabel}
                  description={plan.description}
                  onClick={async (): Promise<void> => {
                    await navigateToCheckout(plan);
                  }}
                ></PlanCard>
              </div>
            );
          })}
        </div>
      </PageSectionLayout>
    </Wrapper>
  );
}

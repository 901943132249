// https://reactrouter.com/web/example/auth-workflow

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import UserContextProvider from "contexts/UserContext";
import Layout from "components/Layout";
import AppStore from "./AppStore";
import Plans from "./Plans";
import Private from "./Private";
import Signin from "./Signin";
import Signup from "./Signup";
import VerifyEmail from "./VerifyEmail";

export default function Router() {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/verify-email"
              element={
                <Private>
                  <VerifyEmail />
                </Private>
              }
            />
            <Route
              path="/plans"
              element={
                <Private requiresEmailVerification>
                  <Plans />
                </Private>
              }
            />
            <Route path="users/:uid" element={<AppStore />} />
            <Route path="*" element={<Navigate to="/signup" />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </UserContextProvider>
  );
}

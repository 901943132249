import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import Button from "components/Button";

interface PlanCardProps {
  description: string;
  name: string;
  onClick: () => void;
  price: string;
}

export default function PlanCard({
  description,
  name,
  onClick,
  price,
}: PlanCardProps) {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleClick = async () => {
    setIsProcessing(true);
    await onClick();
  };

  const buttonLabel = isProcessing
    ? t("PAGE_PLANS_BUTTON_PROCESSING")
    : t("PAGE_PLANS_BUTTON");

  return (
    <div className={styles.container}>
      <div>
        <h4 className={styles.heading}>{name}</h4>
        <p className={styles.item}>
          <h5 className={styles.price}>{t("PAGE_PLANS_PRICE_PREFIX")}</h5>
          <h5 className={`${styles.price} ${styles.value}`}>{price}</h5>
          <h5 className={styles.price}>{t("PAGE_PLANS_PRICE_SUFFIX")}</h5>
        </p>
        <p>{description}</p>
      </div>
      <div className={styles.button}>
        <Button
          onClick={async (): Promise<void> => {
            await handleClick();
          }}
          type="button"
          label={buttonLabel}
          variant="outline"
        ></Button>
      </div>
    </div>
  );
}

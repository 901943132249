import styles from "./index.module.scss";

interface ButtonProps {
  disabled?: boolean;
  label: string;
  onClick?: () => void;
  type: "button" | "submit";
  variant: "outline" | "contained";
}

export default function Button({
  type,
  label,
  variant,
  disabled,
  onClick,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${styles["button--" + variant]}`}
      type={type}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

import { createContext, useContext, useEffect, useState } from "react";
import type { FC } from "react";
import { getAnalytics, setUserId } from "firebase/analytics";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  useDeviceLanguage,
  type User,
} from "firebase/auth";

import { useFirebase } from "contexts/FirebaseContext";

interface UserContextProviderProps {
  children: React.ReactNode;
}

const userContext = createContext<User | null>(null);

const useUser = (): User | null => {
  return useContext(userContext);
};

const UserContextProvider: FC<UserContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const app = useFirebase();
  const auth = getAuth(app);
  const analytics = getAnalytics(app);

  useEffect(() => {
    signOut(auth).catch((error) => {
      console.error(error);
    });
    useDeviceLanguage(auth);
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, [auth]);

  useEffect(() => {
    if (user != null) {
      setUserId(analytics, user.uid);
    }
  }, [analytics, user]);

  return <userContext.Provider value={user}>{children}</userContext.Provider>;
};

export { useUser };
export default UserContextProvider;

interface Plan {
  translationKey: string;
  price: Price;
  storageSizeLimit: string;
}

interface Price {
  id: string;
  en: string;
  ja: string;
}

const configs: Plan[] = [
  {
    translationKey: "PRO",
    price: {
      id:
        process.env.REACT_APP_ENV === "prod"
          ? "price_1MzKy1Fhv980ra9a5XDZvVKD"
          : "price_1MzKw9Fhv980ra9a4Wsp21FN",
      en: "29.99",
      ja: "2,900",
    },
    storageSizeLimit: "50GB",
  },
  {
    translationKey: "TEAM",
    price: {
      id:
        process.env.REACT_APP_ENV === "prod"
          ? "price_1MzKy4Fhv980ra9aKs9ddYxD"
          : "price_1MzKwbFhv980ra9aTZPlmkSx",
      en: "99.99",
      ja: "9,900",
    },
    storageSizeLimit: "200GB",
  },
  {
    translationKey: "BUSINESS",
    price: {
      id:
        process.env.REACT_APP_ENV === "prod"
          ? "price_1MzKy7Fhv980ra9aoH8lMSLE"
          : "price_1MzKwyFhv980ra9awigqviNz",
      en: "299.99",
      ja: "29,800",
    },
    storageSizeLimit: "2TB (2,000GB)",
  },
];

export default configs;

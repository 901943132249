import type { ReactNode } from "react";
import styles from "./index.module.scss";

interface PageSectionLayoutProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
}

export default function PageSectionLayout({
  children,
  title,
  subtitle,
}: PageSectionLayoutProps) {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>{title}</h1>
      {subtitle !== undefined && <p className={styles.subtitle}>{subtitle}</p>}
      <div className={styles.contents}>{children}</div>
    </section>
  );
}

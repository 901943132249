// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5

import { Fragment, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "contexts/UserContext";

interface PrivateProps {
  children: ReactNode;
  requiresEmailVerification?: boolean;
}

export default function Private({
  children,
  requiresEmailVerification,
}: PrivateProps) {
  const user = useUser();

  if (user == null) {
    return <Navigate to="/signin" replace />;
  }
  if (requiresEmailVerification === true && !user.emailVerified) {
    return <Navigate to="/verify-email" replace />;
  }
  return <Fragment>{children}</Fragment>;
}

import type { ReactNode } from "react";
import styles from "./index.module.scss";

interface WrapperProps {
  children: ReactNode;
  style?: object;
}

export default function Wrapper({ children, style }: WrapperProps) {
  return (
    <div className={styles.wrapper} style={style}>
      {children}
    </div>
  );
}

import { createContext, useContext} from "react";
import type { FC } from "react";
import { initializeApp, type FirebaseApp } from "firebase/app";

import config from "configs/firebase";

interface FirebaseContextProviderProps {
  children: React.ReactNode;
}

const app = initializeApp(config);

const firebaseContext = createContext<FirebaseApp>(app);

const useFirebase = (): FirebaseApp => {
  return useContext(firebaseContext);
};

const FirebaseContextProvider: FC<FirebaseContextProviderProps> = ({
  children,
}) => {
  return (
    <firebaseContext.Provider value={app}>{children}</firebaseContext.Provider>
  );
};

export { useFirebase };
export default FirebaseContextProvider;

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable, HttpsCallable } from "firebase/functions";

import { useFirebase } from "contexts/FirebaseContext";

import Button from "components/Button";
import Container from "components/Container";
import FormError from "components/FormError";
import InputField, { Event } from "components/InputField";
import PageSectionLayout from "components/PageSectionLayout";
import Wrapper from "components/Wrapper";

import styles from "./index.module.scss";

interface PortalSession {
  url: string;
}

export default function Signup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const app = useFirebase();

  const [email, setEmail] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const buttonLabel: string = isProcessing
    ? t("PAGE_SIGN_IN_BUTTON_PROCESSING")
    : t("PAGE_SIGN_IN_BUTTON");

  const handleChangeEmail = (event: Event): void => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: Event): void => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
  }): Promise<void> => {
    event.preventDefault();
    if (isProcessing) {
      return;
    }
    try {
      setIsProcessing(true);
      await signin();
    } catch (error) {
      setIsProcessing(false);
      setErrorText(error.message);
    }
  };

  const signin = async (): Promise<void> => {
    try {
      const auth = getAuth(app);
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      if (await hasNonCanceledSubscription(user.uid)) {
        await navigateToPortal();
      } else {
        navigate("/plans");
      }
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          throw new Error(t("FORM_ERROR_INVALID_EMAIL"));
        case "auth/user-disabled":
          throw new Error(t("FORM_ERROR_USER_DISABLED"));
        case "auth/user-not-found":
          throw new Error(t("FORM_ERROR_USER_NOT_FOUND"));
        case "auth/wrong-password":
          throw new Error(t("FORM_ERROR_WRONG_PASSWORD"));
        default:
          throw new Error(t("FORM_ERROR_SIGN_IN_API_ERROR"));
      }
    }
  };

  const hasNonCanceledSubscription = async (uid: string): Promise<boolean> => {
    const firestore = getFirestore(app);
    const subscriptions = await getDocs(
      collection(firestore, `customers/${uid}/subscriptions`),
    );
    const uncanceledSubscriptions = subscriptions.docs
      .map((subscription) => subscription.data().status)
      .filter((status) => status !== "canceled");
    return 0 < uncanceledSubscriptions.length;
  };

  const navigateToPortal = async (): Promise<void> => {
    const functions = getFunctions(app, "us-central1");
    const createPortalLink: HttpsCallable = httpsCallable(
      functions,
      "ext-firestore-stripe-subscriptions-createPortalLink",
    );
    const { data } = await createPortalLink({ returnUrl: t("URL") });
    const portalSession = data as PortalSession;
    window.location.assign(portalSession.url);
  };

  return (
    <Wrapper>
      <Container>
        <PageSectionLayout title={t("PAGE_SIGN_IN_TITLE")}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.item}>
              <InputField
                required={true}
                label={t("FORM_EMAIL")}
                placeholder="you@poslog.com"
                type="email"
                value={email}
                onChange={handleChangeEmail}
              ></InputField>
            </div>
            <div className={styles.item}>
              <InputField
                required={true}
                label={t("FORM_PASSWORD")}
                type="password"
                value={password}
                onChange={handleChangePassword}
              ></InputField>
            </div>
            {errorText !== "" && (
              <div className={styles.error}>
                <FormError text={errorText}></FormError>
              </div>
            )}
            <div className={styles.submit}>
              <Button
                type="submit"
                label={buttonLabel}
                variant="contained"
              ></Button>
            </div>
          </form>
          <p className={styles.text}>
            <Link className={styles.link} to="/signup">
              {t("PAGE_SIGN_IN_LINK_TO_SIGN_UP")}
            </Link>
          </p>
        </PageSectionLayout>
      </Container>
    </Wrapper>
  );
}

import styles from "./index.module.scss";
import WariningIcon from "assets/imgs/warning.svg";

interface FormErrorProps {
  text: string;
}

export default function FormError({ text }: FormErrorProps) {
  return (
    <div className={styles.caution}>
      <img src={WariningIcon} alt="" width="24" height="24" />
      <span>{text}</span>
    </div>
  );
}

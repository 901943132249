import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from "firebase/auth";

import { useFirebase } from "contexts/FirebaseContext";

import Button from "components/Button";
import Container from "components/Container";
import FormError from "components/FormError";
import InputField, { Event } from "components/InputField";
import PageSectionLayout from "components/PageSectionLayout";
import Wrapper from "components/Wrapper";

import styles from "./index.module.scss";

interface AgreementText {
  TEXT: string;
  URL?: string;
}

export default function Signup() {
  const { t } = useTranslation();
  const app = useFirebase();
  const auth = getAuth(app);
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const buttonLabel: string = isProcessing
    ? t("PAGE_SIGN_UP_BUTTON_PROCESSING")
    : t("PAGE_SIGN_UP_BUTTON");

  const agreementTexts: AgreementText[] = t("PAGE_SIGN_UP_AGREEMENT_TEXTS", {
    returnObjects: true,
  });

  const isSubmittable = (): boolean => {
    return email !== "" && password !== "" && isChecked;
  };

  const handleChangeEmail = (event: Event): void => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: Event): void => {
    setPassword(event.target.value);
  };

  const handleChangePasswordConfirmation = (event: Event): void => {
    setPasswordConfirmation(event.target.value);
  };

  const handleChangeCheckbox = (event: Event): void => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
  }): Promise<void> => {
    event.preventDefault();
    if (isProcessing) {
      return;
    }
    if (password !== passwordConfirmation) {
      setErrorText(t("FORM_PASSWORD_CONFIRMATION_ERROR"));
      return;
    }
    try {
      setIsProcessing(true);
      await signup();
    } catch (error) {
      setIsProcessing(false);
      setErrorText(error.message);
    }
  };

  const signup = async (): Promise<void> => {
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      await sendEmailVerification(user, {
        url: "https://app.poslog.com/plans",
      });
      navigate("/plans");
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          throw Error(t("FORM_ERROR_INVALID_EMAIL"));
        case "auth/email-already-in-use":
          throw Error(t("FORM_ERROR_EMAIL_ALREADY_IN_USE"));
        case "auth/weak-password":
          throw Error(t("FORM_ERROR_WEAK_PASSWORD"));
        default:
          throw Error(t("FORM_ERROR_SIGN_UP_API_ERROR"));
      }
    }
  };

  return (
    <Wrapper>
      <Container>
        <PageSectionLayout title={t("PAGE_SIGN_UP_TITLE")}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.item}>
              <InputField
                required={true}
                label={t("FORM_EMAIL")}
                placeholder="you@poslog.com"
                type="email"
                value={email}
                onChange={handleChangeEmail}
              ></InputField>
            </div>
            <div className={styles.item}>
              <InputField
                required={true}
                label={t("FORM_PASSWORD")}
                type="password"
                value={password}
                min={6}
                onChange={handleChangePassword}
              ></InputField>
              <p className={styles.caution}>{t("PAGE_SIGN_UP_CAUTION")}</p>
            </div>
            <div className={styles.item}>
              <InputField
                required={true}
                label={t("FORM_PASSWORD_CONFIRMATION")}
                type="password"
                value={passwordConfirmation}
                min={6}
                onChange={handleChangePasswordConfirmation}
              ></InputField>
            </div>
            <div className={styles.item}>
              <label className={styles.checkbox}>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    handleChangeCheckbox(e);
                  }}
                />
                <p>
                  {agreementTexts.map((text: AgreementText, index) => {
                    return text.URL !== undefined ? (
                      <a
                        key={index}
                        href={t("URL") + text.URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {text.TEXT}
                      </a>
                    ) : (
                      <span key={index}>{text.TEXT}</span>
                    );
                  })}
                </p>
              </label>
            </div>
            {errorText !== "" && (
              <div className={styles.error}>
                <FormError text={errorText}></FormError>
              </div>
            )}
            <div className={styles.submit}>
              <Button
                type="submit"
                label={buttonLabel}
                variant="contained"
                disabled={!isSubmittable()}
              ></Button>
            </div>
          </form>
          <p className={styles.text}>
            <Link className={styles.link} to="/signin">
              {t("PAGE_SIGN_UP_LINK_TO_SIGN_IN")}
            </Link>
          </p>
        </PageSectionLayout>
      </Container>
    </Wrapper>
  );
}

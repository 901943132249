// https://react.i18next.com/guides/quick-start
// https://react.i18next.com/latest/using-with-hooks

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locale/en/translation.json";
import translationJA from "./locale/ja/translation.json";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  en: translationEN,
  ja: translationJA,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  })
  .catch(console.error);

export default i18n;
